import React from "react";
import Icon from "../components/Icon";
import { toCurrency } from "../utils";
import DriverTipDialog from "./DriverTipDialog";
import ConfirmationDialog from "./ConfirmationDialog";
import UpdateItemDialog from "./UpdateItemDialog";

const confirmationMessages = {
  bagFee: {
    header: "Insulated tote bag fee",
    message:
      "Since this is your first order with us, you will be charged a one-time fee for the reuseable insulated tote bag we use to store your food. You will not be charged for this bag in subsequent orders.",
    btnText: "GOT IT",
  },
  deliveryFee: {
    header: "Delivery fee waived",
    message:
      "Your delivery fee has been waived because you have another order scheduled for the same date and delivery location.",
    btnText: "GOT IT",
  },
  serviceFee: {
    header: "Service fee added",
    message:
      "We require a minimum order total of $0.50, so we have added a service fee to your order to meet this minimum requirement.",
    btnText: "GOT IT",
  },
};

export default ({
  setInputOrderData,
  inputOrderData,
  updateOrder,
  outputOrderData,
}) => {
  const [confirmationProps, setConfirmationProps] = React.useState(null);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [updateItem, setUpdateItem] = React.useState(null);
  const [editTip, setEditTip] = React.useState(false);

  function handleConfirmationClick(type) {
    const props = confirmationMessages[type];
    props.handleConfirm = () => setConfirmationOpen(false);
    props.handleExited = () => setConfirmationProps(null);
    setConfirmationProps(props);
    setConfirmationOpen(true);
  }

  return (
    <>
      {confirmationProps && (
        <ConfirmationDialog
          dialogOpen={confirmationOpen}
          {...confirmationProps}
        />
      )}
      <UpdateItemDialog
        updateItem={updateItem}
        updateOrder={updateOrder}
        setUpdateItem={setUpdateItem}
      />
      <DriverTipDialog
        open={editTip}
        setOpen={setEditTip}
        setInputOrderData={setInputOrderData}
        handleSubmit={(tipAmount) => {
          updateOrder({ tipAmount });
          setEditTip(false);
        }}
        tipAmount={inputOrderData.tipAmount}
        orderTotal={
          outputOrderData.stripePaymentIntent.amount - inputOrderData.tipAmount
        }
      />
      <table className="order-items-table">
        <thead>
          <tr>
            <th>Item</th>
            <th>Qty</th>
            <th>Price</th>
            <th colSpan="2">Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {inputOrderData.items.map(
            ({ itemDisplayName, price, qty, itemID }, index) => {
              const handleIconClick = () =>
                setUpdateItem(inputOrderData.items[index]);
              return (
                <tr key={`item-${index}`}>
                  <td>{itemDisplayName}</td>
                  <td>{qty}</td>
                  <td>${toCurrency(price)}</td>
                  <td>${toCurrency(price * qty)}</td>
                  <td className="action-cell">
                    {outputOrderData.couponData &&
                    outputOrderData.couponData.freeProductID ===
                      itemID ? null : (
                      <button
                        type="button"
                        title="Edit this item"
                        className="btn edit-item-btn"
                        onClick={handleIconClick}
                      >
                        <Icon iconName="edit" width={16} />
                      </button>
                    )}
                  </td>
                </tr>
              );
            }
          )}
          {outputOrderData.couponData && (
            <tr>
              <td colSpan="3" className="text-primary">
                Coupon discount (code:{" "}
                <strong>{outputOrderData.couponData.code}</strong>)
              </td>
              <td className="text-primary subtotal" colspan="2">
                (${toCurrency(outputOrderData.couponData.discount)})
              </td>
            </tr>
          )}
          <tr className={`total-section-row total-section-header`}>
            <td colSpan="3">Delivery fee:</td>
            <td>${toCurrency(outputOrderData.deliveryFee)}</td>
            <td className="action-cell">
              {outputOrderData.deliveryFee === 0 && (
                <button
                  title="Learn why your fee is waived"
                  type="button"
                  className="btn edit-item-btn"
                  onClick={() => handleConfirmationClick("deliveryFee")}
                >
                  <Icon iconName="question" width={18} />
                </button>
              )}
            </td>
          </tr>
          {outputOrderData.bagFee > 0 && (
            <tr className={`total-section-row`}>
              <td colSpan="3">Insulated tote bag:</td>
              <td>${toCurrency(outputOrderData.bagFee)}</td>
              <td className="action-cell">
                <button
                  title="Learn why you're being charged for this"
                  type="button"
                  className="btn edit-item-btn"
                  onClick={() => handleConfirmationClick("bagFee")}
                >
                  <Icon iconName="question" width={18} />
                </button>
              </td>
            </tr>
          )}
          {outputOrderData.serviceFee > 0 && (
            <tr className={`total-section-row`}>
              <td colSpan="3">Service fee:</td>
              <td>${toCurrency(outputOrderData.serviceFee)}</td>
              <td className="action-cell">
                <button
                  title="Learn why you're being charged for this"
                  type="button"
                  className="btn edit-item-btn"
                  onClick={() => handleConfirmationClick("serviceFee")}
                >
                  <Icon iconName="question" width={18} />
                </button>
              </td>
            </tr>
          )}
          <tr className={`total-section-row`}>
            <td colSpan="3">Driver tip:</td>
            <td>
              <button
                onClick={() => setEditTip(true)}
                style={{ padding: 0, font: "inherit", background: "none" }}
                className="btn"
              >
                ${toCurrency(inputOrderData.tipAmount)}
              </button>
            </td>
            <td className="action-cell">
              <button
                title="Edit tip amount"
                type="button"
                className="btn edit-item-btn"
                onClick={() => setEditTip(true)}
              >
                <Icon iconName="edit" width={16} />
              </button>
            </td>
          </tr>
          <tr className="total-section-row order-total-row">
            <td colSpan="3">Order total:</td>
            <td>${toCurrency(outputOrderData.stripePaymentIntent.amount)}</td>
            <td className="action-cell"></td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
