import React from 'react';
import Dialog from '../components/Dialog';
import Icon from '../components/Icon';

export default ({ updateItem, updateOrder, setUpdateItem }) => {
    const [open, setOpen] = React.useState(false);
    const [qty, setQty] = React.useState(0);
    function updateQty(amount) {
        setQty(qty + amount);
    }
    function handleSubmit() {
        if (updateItem) {
            updateOrder({ item: {
                itemID: updateItem.itemID,
                newQty: qty,
                priceOptionItemName: updateItem.priceOptionItemName
            }});
            setOpen(false)
        }
    }
    React.useEffect(() => {
        if(updateItem) {
            setOpen(true)
            setQty(updateItem.qty)
        }
    }, [updateItem]);
    const submitDisabled = updateItem && updateItem.qty === qty;
    return (
        <Dialog maxWidth={450} open={open} onExited={() => setUpdateItem(null)}>
            <div className='add-padding-2 position-relative'>
                <button className='btn close-btn' onClick={() => setOpen(false)}>
                    <Icon iconName='times' width={18} />
                </button>
                <h5 className='margin-bottom-2'>Update item quantity</h5>
                <p>{updateItem && updateItem.itemDisplayName}</p>
                <div className='qty-container alt'>
                    <div>
                        <button className={`btn${qty === 0 ? ' disabled' : ''}`} onClick={() => updateQty(-1)} disabled={qty === 0}>
                            <Icon width={16} iconName="minus" />
                        </button>
                    </div>
                    <div className='qty'>{qty}</div>
                    <div>
                        <button className={`btn`} onClick={() => updateQty(1)}>
                            <Icon width={16} iconName="plus" />
                        </button>
                    </div>
                </div>
                <div>
                    <button disabled={submitDisabled} className={`btn small-btn primary-btn full-width ${submitDisabled ? ' disabled' : ''}`} onClick={handleSubmit}>{qty === 0 ? "REMOVE ITEM" : "SAVE CHANGES"}</button>
                </div>
            </div>
        </Dialog>
    )

}