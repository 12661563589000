import React from 'react';
import Icon from './Icon';
import Dialog from './Dialog';
import { AuthContext } from './Layout';
import ConfirmationDialog from './ConfirmationDialog';
import { toCurrency, postWithToken, getAxiosErrorMessage } from '../utils';
import { BASE_PATH } from '../config';

export default ({ inputOrderData, setInputOrderData, outputOrderData, updateOrder }) => {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [submitting, setSubmitting] = React.useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const user = React.useContext(AuthContext);
    React.useEffect(() => {
        if (inputOrderData.couponCode) {
            handleClose()
        }
    }, [inputOrderData.couponCode])

    function handleExited() {
        if (inputOrderData.couponCode) {
            updateOrder({ couponCode: inputOrderData.couponCode });
        }
    }

    function handleRemove() {
        setConfirmationDialogOpen(true);
    }

    function handleClose() {
        setErrorMessage('');
        setValue('');
        setOpen(false);
    }
    function handleSubmit(event) {
        event.preventDefault();
        setSubmitting(true);
        setErrorMessage('');
        postWithToken(user.getIdToken(), `${BASE_PATH}/checkCoupon`, { 
            code: value, 
            orderTotal: inputOrderData.items.reduce((a, b) => a + (b.price * b.qty), 0)
        })
        .then(({ data }) => {
            setInputOrderData({...inputOrderData, couponCode: data.code});
        })
        .catch(err => {
            setErrorMessage(getAxiosErrorMessage(err));
        })
        .finally(() => setSubmitting(false))
    }
    const disabled = submitting || !value;
    function renderContent() {
        if (outputOrderData.couponData) {
            return (
                <div className='checkout-action-container'>
                    <div className='info-container'>
                        <div className='info text-primary'>Coupon code <strong>{outputOrderData.couponData.code}</strong> applied for ${toCurrency(outputOrderData.couponData.discount)} off</div>
                    </div>
                    <div className='action-btn-container'>
                        <button className='btn' title="Remove this coupon code" onClick={handleRemove}><Icon iconName='times' width={16} /></button>
                    </div>
                </div>
            )
        }
        else {
            return <button className='btn checkout-option-btn' onClick={() => setOpen(true)}>Add coupon?</button>
        }
    }
    return (
        <>
            <div className='checkout-option-btn-container'>
                {renderContent()}
            </div>
            <Dialog open={open} disableBackdropClick maxWidth={450} onClose={handleClose} onExited={handleExited}>
                <div className='add-padding-2 position-relative'>
                    <button className='btn close-btn' onClick={handleClose}>
                        <Icon iconName='times' width={18} />
                    </button>
                    <h5 className='margin-bottom-2'>Add a coupon code</h5>
                    <form onSubmit={handleSubmit}>
                        <input 
                            name='promoCode' 
                            placeholder="Enter code" 
                            className='form-input'
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                        />
                        {errorMessage && <div className='error-msg'>{errorMessage}</div>}
                        <button type="submit" className={`btn primary-btn full-width${disabled ? ' disabled': ''}`} disabled={disabled}>{submitting ? 'VALIDATING...' : 'APPLY CODE'}</button>
                    </form>
                </div>
            </Dialog>
            <ConfirmationDialog 
                dialogOpen={confirmationDialogOpen}
                header="Remove coupon"
                message="Would you like to remove this coupon code from your order?"
                btnText="YES, REMOVE"
                btnClass="error-btn"
                handleCancel={() => setConfirmationDialogOpen(false)}
                handleConfirm={() => {
                    updateOrder({ couponCode: null});
                }}
            />
        </>
    )
}