import React from 'react';
import { Link } from 'gatsby';
import Dialog from '@material-ui/core/Dialog';
import DeliveryLocationList from '../DeliveryLocationList';
import CheckoutSummaryTable from '../CheckoutSummaryTable';
import AutocompleteInput from '../AutocompleteInput';
import Layout, { AuthContext } from '../Layout';
import PaymentMethodInfo from '../PaymentMethodInfo';
import Icon from '../Icon';
import StripeCheckoutForm from '../StripeCheckoutForm';
import StripeCardForm from '../StripeCardForm';
import { getLocalStorage, ZIP_CODE, AUTH_USER, makeCancellable, postWithToken, fetchWithToken, getAxiosErrorMessage, removeLocalStorage, CART } from '../../utils';
import LoadingContainer from '../LoadingContainer';
import StripeElementsWrapper from '../StripeElementsWrapper';
import PaymentMethodList from '../PaymentMethodList';
import CouponDialog from '../CouponDialog';
import ConfirmationDialog from '../ConfirmationDialog';
import Slide from '@material-ui/core/Slide';
import { DELIVERY_WINDOW, FOOD_ROUTE } from '../../constants';
import { BASE_PATH } from '../../config';
import DeliveryNotesDialog from '../DeliveryNotesDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const createOrderItem = (itemDisplayName, qty, price, priceOptionItemName, itemID) => ({ itemDisplayName, qty, price, priceOptionItemName, itemID });

const CheckoutComponent = ({ cartItems }) => {
    const initOrderItems = cartItems.map(({ qty, itemDetails: { name, farmName, price, priceOptionItemName, itemID } }) => {
        return createOrderItem(`${farmName} - ${name}${priceOptionItemName ? " - " + priceOptionItemName : ''}`, qty, price, priceOptionItemName, itemID);
    });
    const [fetching, setFetching] = React.useState({isUpdate: false, isFetching: true});
    const [inputOrderData, setInputOrderData] = React.useState({items: initOrderItems, tipAmount: 0});
    const [outputOrderData, setOutputOrderData] = React.useState({});
    const [defaultDeliveryLocationExists, setDefaultDeliveryLocationExists] = React.useState(false);
    const [touched, setTouched] = React.useState({ deliveryLocation: false, paymentMethod: false });
    const [deliveryLocation, setDeliveryLocation] = React.useState('');
    const [orderExceptions, setOrderExceptions] = React.useState([]);
    const [updateOrderException, setUpdateOrderException] = React.useState('');
    const [paymentMethod, setPaymentMethod] = React.useState(null);
    const [accountDetails, setAccountDetails] = React.useState({fetching: true});
    const [editing, setEditing] = React.useState({type: '', dialogOpen: false});
    const [orderError, setOrderError] = React.useState('');
    const [orderExpired, setOrderExpired] = React.useState(false);
    const user = React.useContext(AuthContext);
    const authUser = getLocalStorage(AUTH_USER);

    function updateStateWithOrderData(data, type) {
        if (type === "update") {
            if (data.orderExceptions.length > 0) {
                setUpdateOrderException(`${data.orderExceptions[0].item}: ${data.orderExceptions[0].message}`)
            }
        }
        else {
            if (!deliveryLocation && data.defaultDeliveryLocation) {
                setDeliveryLocation(data.defaultDeliveryLocation);
            }
            if (!paymentMethod && data.defaultPaymentMethod) {
                setPaymentMethod(data.defaultPaymentMethod);
            }
            setDefaultDeliveryLocationExists(Boolean(data.defaultDeliveryLocation))
            setOrderExceptions(data.orderExceptions)
        }
        setOutputOrderData({ 
            stripePaymentIntent: data.stripePaymentIntent, 
            deliveryDate: data.deliveryDate,
            stripeCustomerID: data.stripeCustomerID,
            deliveryFee: data.deliveryFee,
            bagFee: data.bagFee,
            couponData: data.couponData,
            serviceFee: data.serviceFee
        });
        setInputOrderData({ 
            items: data.items.map(({ itemDisplayName, qty, price, priceOptionItemName, itemID }) => {
                return createOrderItem(itemDisplayName, qty, price, priceOptionItemName, itemID);
            }),
            tipAmount: data.tipAmount,
            couponCode: data.couponData ? data.couponData.code : undefined,
            deliveryNotes: data.deliveryNotes
        });
        setFetching({ isUpdate: true, isFetching: false });
    }

    function updateOrder(input) {
        setFetching({ isUpdate: true, isFetching: true });
        const wrappedFunction = makeCancellable(postWithToken(user.getIdToken(), `${BASE_PATH}/updateStagedOrder`, input));
        wrappedFunction.promise
        .then(({ data }) => {
            updateStateWithOrderData(data, "update");
        })
        .catch(err => {
            setOrderError(getAxiosErrorMessage(err));
            setFetching({isUpdate: true, isFetching: false});
        })
        return () => wrappedFunction.cancel();
    }

    function reserveOrder({ items, tipAmount, couponCode, deliveryNotes }) {
        if (cartItems.length > 0 && getLocalStorage(ZIP_CODE)) {
            setFetching({ isUpdate: false, isFetching: true });
            const wrappedFunction = makeCancellable(postWithToken(user.getIdToken(), `${BASE_PATH}/reserveOrder`, { items, tipAmount, couponCode, deliveryLocation, deliveryNotes }));
            wrappedFunction.promise
            .then(({ data }) => {
                updateStateWithOrderData(data);
            })
            .catch(err => {
                const errorMessage = getAxiosErrorMessage(err);
                if (errorMessage === "None of the items you have requested are available, so we cannot complete your order") {
                    removeLocalStorage(CART);
                }
                setOrderError(errorMessage);
                setFetching({isUpdate: true, isFetching: false});
            })
            return () => wrappedFunction.cancel();
        }
        else {
            setFetching({isUpdate: true, isFetching: false});
        }
    }
    React.useEffect(() => {
        if (user) {
            const wrappedFunction = makeCancellable(fetchWithToken(user.getIdToken(), `${BASE_PATH}/getAccountDetails`));
            wrappedFunction.promise
            .then(({ data }) => {
                setAccountDetails({...data, fetching: false});
            })
            .catch((err) => console.log(err))
            return () => wrappedFunction.cancel();
        }
    }, [user])
    React.useEffect(() => {
        if (user) {
            window.onbeforeunload = () => {
                return "You have unsaved changes. Are you sure you want to exit?"
            };
            return reserveOrder(inputOrderData);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setOrderExpired(true);
        }, 1000 * 60 * 30);
        return () => clearTimeout(timeout);
    }, [orderExpired])

    function renderEditDialog() {
        let content;
        if (accountDetails.fetching) {
            content = <LoadingContainer text="Loading your account details..." />
        }
        else if (editing.type === "deliveryLocation") {
            content = ( 
                <>
                    <h5 className='underline-heading'>Your delivery locations</h5>
                    <DeliveryLocationList
                        selectedVerbiage="SELECTED"
                        deliveryLocations={accountDetails.deliveryLocations}
                        selectedDeliveryLocation={deliveryLocation}
                        setDeliveryLocations={deliveryLocations => setAccountDetails({...accountDetails, deliveryLocations})}
                        setSelectedDeliveryLocation={location => {
                            setDeliveryLocation(location);
                            setEditing({...editing, dialogOpen: false});
                            updateOrder({ deliveryLocation: location });
                        }}
                        handleLocationAdded={(location) => {
                            setAccountDetails({...accountDetails, deliveryLocations: [...accountDetails.deliveryLocations, location]}); 
                            setDeliveryLocation(location);
                            if (deliveryLocation) { // Only reserve a new order if there was a delivery location before...if it's a first time order, there won't be a delivery location so there's no need to update
                                updateOrder({ deliveryLocation: location });
                            }
                        }}
                        handleLocationsUpdated={({ deliveryLocations, defaultDeliveryLocation }, type) => {
                            if (type === 'update' && deliveryLocation !== defaultDeliveryLocation) {
                                updateOrder({ deliveryLocation: defaultDeliveryLocation });
                                setEditing({...editing, dialogOpen: false});
                            } 
                            setAccountDetails({...accountDetails, deliveryLocations });
                            setDeliveryLocation(defaultDeliveryLocation);
                        }}
                    />
                </>
            )
        }
        else if (editing.type === "paymentMethod") {
            content = ( 
                <>
                    <h5 className='underline-heading'>Your payment methods</h5>
                    <PaymentMethodList
                        selectedVerbiage="SELECTED"
                        paymentMethods={accountDetails.paymentMethods}
                        selectedPaymentMethod={paymentMethod}
                        stripeCustomerID={outputOrderData.stripeCustomerID}
                        processPaymentRemoval={data => {
                            setAccountDetails({...accountDetails, paymentMethods: data.paymentMethods});
                            setPaymentMethod(data.defaultPaymentMethod);
                        }}
                        handlePaymentMethodCreated={method => {
                            setAccountDetails({
                                ...accountDetails, 
                                paymentMethods: [...accountDetails.paymentMethods, method]
                            });
                            setPaymentMethod(method);
                            setEditing({...editing, dialogOpen: false});
                        }}
                        handleSelectClick={method => {
                            setPaymentMethod(method);
                            setEditing({...editing, dialogOpen: false});
                        }}
                    />
                </>
            )
        }
        const open = editing.dialogOpen && (accountDetails.fetching || (editing.type === "paymentMethod" && accountDetails.paymentMethods.length > 0) || (editing.type === "deliveryLocation" && accountDetails.deliveryLocations.length > 0));
        if (content) {
            return (
                <Dialog open={open} fullWidth PaperProps={{style: {maxWidth: 600, width: '100%', margin: 12, overflowY: 'visible'}}} disableBackdropClick TransitionComponent={Transition}>
                    <div className='add-padding-2 position-relative'>
                        <button className='btn close-btn' onClick={() => setEditing({...editing, dialogOpen: false})}>
                            <Icon iconName='times' width={18} />
                        </button>
                        {content}
                    </div>
                </Dialog>
            )
        }
        return null;
    }
    function renderDeliveryLocation() {
        if (defaultDeliveryLocationExists && deliveryLocation) {
            return (
                <div className='checkout-action-container'>
                    <div className='info-container'>
                        <p className='info'>{deliveryLocation}</p>
                    </div>
                    <div className='action-btn-container'>
                        <button className='btn' title='Edit address' onClick={() => setEditing({type: "deliveryLocation", dialogOpen: true})}>
                            <Icon iconName='edit' width={16} />
                        </button>
                    </div>
                </div>
            )
        }
        return (
            <>
                <AutocompleteInput 
                    placeholder="Enter a delivery location" 
                    field={{value: deliveryLocation, onChange: (e) => setDeliveryLocation(e.target.value), name: "location"}} 
                    setFieldValue={(name, value) => setDeliveryLocation(value)}
                    invalid={touched.deliveryLocation && !deliveryLocation}
                    blurAction={() => setTouched({...touched, deliveryLocation: true})}
                />
                {touched.deliveryLocation && !deliveryLocation && <p className='error-msg alt-marg'>Please enter a valid address</p>}
            </>
        )
    }
    function renderPaymentMethod() {
        if (paymentMethod) {
            return (
                <div className='checkout-action-container'>
                    <div className='info-container'>
                        <PaymentMethodInfo paymentMethod={paymentMethod} />
                    </div>
                    <div className='action-btn-container'>
                        <button className='btn' title='Edit payment method' onClick={() => setEditing({type: "paymentMethod", dialogOpen: true})}>
                            <Icon iconName='edit' width={16} />
                        </button>
                    </div>
                </div>
            )
        }
        return (
            <StripeCardForm 
                stripeCustomerID={outputOrderData.stripeCustomerID}
                handlePaymentMethodCreated={(method) => {
                    setPaymentMethod(method);
                    setAccountDetails({...accountDetails, paymentMethods: [method], defaultPaymentMethod: method});
                }}
            />  
        )
    }
    function renderContent() {
        if (orderError) {
            return (
                <div className='text-center'>
                    <p>An error occurred while processing your order:</p>
                    <p><strong>{orderError}</strong></p>
                    <p className='add-padding-2'><Link to="/">Go back to home page</Link></p>
                </div>
            )
        }
        else if (cartItems.length === 0) {
            return (
                <div className='text-center'>
                    <p>You don't have anything in your cart!</p>
                    <p><Link to={FOOD_ROUTE}>Go back to explore our food page</Link></p>
                </div>
            )
        }
        else if (!getLocalStorage(ZIP_CODE)) {
            return (
                <div className='text-center'>
                    <p>You didn't enter your zip code! <Link to={FOOD_ROUTE}>Enter your zip code</Link>, then come back.</p>
                </div>
            )
        }
        else if (fetching.isFetching) {
            if (fetching.isUpdate) {
                return (
                    <div className='updating-container'>
                        <LoadingContainer text="Please wait while we update your order..." />
                    </div>
                )
            }
            return <LoadingContainer text="Please wait while we reserve your items..." />
        }
        else if (outputOrderData.stripePaymentIntent && inputOrderData.items) {
            let checkoutItems = [
                {
                    header: "DELIVERY DATE",
                    content: <p>{outputOrderData.deliveryDate} {DELIVERY_WINDOW}</p>
                },
                {
                    header: "ADDRESS",
                    content: (
                        <>
                            {renderDeliveryLocation()}
                            <DeliveryNotesDialog updateOrder={updateOrder} inputOrderData={inputOrderData} />
                        </>
                    )
                },
                {
                    header: "PAYMENT",
                    content: (
                        <>
                            {renderPaymentMethod()}
                            <CouponDialog
                                inputOrderData={inputOrderData}
                                setInputOrderData={setInputOrderData}
                                outputOrderData={outputOrderData}
                                updateOrder={updateOrder}
                            />
                        </>
                    )
                },
                {
                    header: "SUMMARY",
                    content: (
                        <CheckoutSummaryTable 
                            inputOrderData={inputOrderData}
                            setInputOrderData={setInputOrderData}
                            updateOrder={updateOrder}
                            outputOrderData={outputOrderData}
                        />
                    )
                },
            ];
            if (orderExceptions.length > 0) {
                checkoutItems.unshift({
                    header: "ORDER EXCEPTIONS",
                    content: (
                        <div>
                            <h6>The following exceptions apply to your order:</h6>
                            <div className='order-exceptions-container'>
                                <ul>
                                    {orderExceptions.map(({item, message}, index) => {
                                        return (
                                            <li key={`exception-${index}`}><strong>{item}</strong> - {message}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    )
                })
            }
            return (
                 <StripeElementsWrapper>
                    <h4>Review your order</h4>
                    {checkoutItems.map( ({ header, content, editContent }, index) => {
                        return (
                            <section key={`checkout-section-${index}`} className={checkoutItems.length - 1 === index ? 'no-border' : ''}>
                                <div className='header-item'><h6>{header}</h6></div>
                                <div className='content'>{content}</div>
                            </section>
                        )
                    })}
                    <StripeCheckoutForm 
                        paymentMethod={paymentMethod} 
                        orderTotal={outputOrderData.stripePaymentIntent.amount}
                        isEditing={Boolean(editing.dialogOpen)}
                        clientSecret={outputOrderData.stripePaymentIntent["client_secret"]}
                        authUser={authUser}
                        deliveryLocation={deliveryLocation}
                        setTouched={() => setTouched({ deliveryLocation: true, paymentMethod: true })}
                    />
                    {renderEditDialog()}
                    <ConfirmationDialog 
                        dialogOpen={orderExpired} 
                        handleConfirm={() => {
                            setOrderExpired(false);
                            reserveOrder(inputOrderData);
                        }}
                        header="Order released"
                        message="Your item reservations have been released due to inactivity. Please refresh your order to continue checking out."
                        btnText="REFRESH ORDER"
                    />
                    <ConfirmationDialog 
                        dialogOpen={Boolean(updateOrderException)}
                        header="Order alert"
                        message={updateOrderException}
                        btnText="GOT IT"
                        handleConfirm={() => setUpdateOrderException('')}
                    />
                </StripeElementsWrapper>
            )
        }
        return (
            <div className='text-center'>
                <p>An error occurred while processing your order</p>
                <p>Please <Link to="/contact/">contact us</Link>, or try again later.</p>
            </div>
        )
    }
    return (
        <div>
            <div className='green-heading'>
                <h2>CHECKOUT</h2>
            </div>
            <div className='layout-container'>
                <div className='checkout-container'>{renderContent()}</div>
            </div>
        </div>
    )
}

export default () => {
    return (
        <Layout pageTitle="Place your order" addLayoutProps checkoutPage>
            <CheckoutComponent />
        </Layout>
    )
}