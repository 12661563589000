import React from "react";
import { Router } from "@reach/router";
import { Link, useStaticQuery, graphql } from "gatsby";
import CheckoutPage from "../components/pageComponents/CheckoutPage";
import PrivateRoute from "../components/PrivateRoute";
import Layout from "../components/Layout";
import PaymentSuccessPage from "../components/pageComponents/PaymentSuccessPage";

const MarketClosed = () => {
  return (
    <Layout pageTitle="Market closed" ogUrl="/checkout/">
      <div className="text-center add-padding-2">
        <h2 className="margin-bottom-2" style={{ marginTop: 32 }}>
          MARKET CLOSED
        </h2>
        <p style={{ maxWidth: 450, margin: "16px auto 32px auto" }}>
          Sorry for the trouble, but the till market is currently closed. Please
          check back later to complete your purchase!
        </p>
        <Link to="/" className="btn primary-outline-btn">
          BACK TO HOME PAGE
        </Link>
      </div>
    </Layout>
  );
};

export default () => {
  const {
    marketStatus: { marketOpen },
  } = useStaticQuery(graphql`
    query {
      marketStatus {
        marketOpen
      }
    }
  `);
  return (
    <Router basepath="/checkout">
      {marketOpen ? (
        <>
          <PrivateRoute
            component={PaymentSuccessPage}
            path="/payment-success/"
          />
          <PrivateRoute component={CheckoutPage} path="/" />
        </>
      ) : (
        <PrivateRoute component={MarketClosed} path="/" />
      )}
    </Router>
  );
};
