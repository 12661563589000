import React from 'react';
import { Link } from 'gatsby';
import Icon from '../Icon';
import Layout from '../Layout';

export default () => {
    return (
        <Layout pageTitle="Payment received">
            <div className='text-center result-dialog-container margin-top-4'>
                <div className='icon-container'>
                    <Icon iconName="check" width={60} />
                </div>
                <div style={{maxWidth: 450, margin: 'auto'}}>
                    <p style={{fontSize: 18, margin: '16px auto 32px auto'}}>
                        <strong>Success! We have received your payment. You will receive an email confirmation and a record of your order once your order has been processed.</strong>
                    </p>
                    <Link to='/account/orders/' className='btn primary-outline-btn'>VIEW YOUR ORDER HISTORY</Link>
                </div>
            </div>
        </Layout>
    )
}