import React from "react";
import { navigate } from "gatsby";
import { useStripe } from "@stripe/react-stripe-js";
import { toCurrency, removeLocalStorage, CART } from "../utils";

export default ({
  paymentMethod,
  orderTotal,
  clientSecret,
  authUser,
  deliveryLocation,
  setTouched,
  isEditing,
}) => {
  const [formError, setFormError] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const stripe = useStripe();
  const complete = Boolean(paymentMethod && deliveryLocation && !isEditing);
  const renderBottomText = () => {
    if (formError) {
      if (/A source must be attached/.test(formError)) {
        return (
          <div className="error-msg alt-marg">
            <strong>
              An error occurred processing your payment. To fix this error,
              please delete your currently saved card, then re-enter your
              payment method information.
            </strong>
          </div>
        );
      }
      return (
        <div className="error-msg alt-marg">
          <strong>
            {formError} You may need to refresh the page and try again.
          </strong>
        </div>
      );
    } else if (!complete) {
      return (
        <div className="helper-text alt-marg">
          <strong>
            Please enter your address and save a payment method before
            submitting
          </strong>
        </div>
      );
    }
    return null;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !paymentMethod || !deliveryLocation) {
      setTouched();
      return;
    }
    setSubmitting(true);
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethod.id,
      shipping: {
        address: {
          line1: deliveryLocation,
        },
        name: authUser.displayName,
      },
    });
    if (result.error) {
      setSubmitting(false);
      setFormError(result.error.message);
    } else {
      if (result.paymentIntent.status === "succeeded") {
        window.onbeforeunload = () => null;
        removeLocalStorage(CART);
        navigate("/checkout/payment-success/");
      }
    }
  };
  const disabled = !stripe || !complete || submitting;
  return (
    <form onSubmit={handleSubmit}>
      <section className="no-border last-section">
        <div className="header-item"></div>
        <div className="content submit-btn-container">
          <button
            type="submit"
            className={`btn primary-btn${disabled ? " disabled" : ""}`}
            disabled={disabled}
          >
            <strong>{`PAY $${toCurrency(orderTotal)}`}</strong>
          </button>
          {renderBottomText()}
        </div>
      </section>
    </form>
  );
};
