import React from 'react';
import CurrencyInput from './CurrencyInput';
import Icon from './Icon';
import Dialog from './Dialog';
import { getPriceInCents, toCurrency } from '../utils';

const percentages = [10, 15, 20];

export default ({ handleSubmit, open, setOpen, tipAmount, orderTotal }) => {
    const initValue = tipAmount > 0 ? toCurrency(tipAmount) : '0.00';
    const [value, setValue] = React.useState(initValue);
    const calculatePercentage = percentage => toCurrency(Math.floor(percentage * orderTotal / 100));
    function handleCancel() {
        setValue(initValue);
        setOpen(false);
    }
    function handleClick(percentage) {
        setValue(calculatePercentage(percentage));
    }
    function handleExit() {
        const amountInCents = getPriceInCents(value);
        if (amountInCents !== tipAmount) {
            handleSubmit(amountInCents);
        }
    }
    const disabled = !value || getPriceInCents(value) === 0;
    return (
        <Dialog maxWidth={450} open={open} disableBackdropClick onExit={handleExit}>
            <div className='add-padding-2 position-relative'>
                <button className='btn close-btn' onClick={handleCancel}>
                    <Icon iconName='times' width={18} />
                </button>
                <h5 className='margin-bottom-2'>Add a driver tip</h5>
                <div className='margin-bottom-2 grid-container add-width-1'>
                    {percentages.map(percentage => {
                        return (
                            <div className='add-padding grid-item-3' key={`${percentage}`}>
                                <button onClick={() => handleClick(percentage)} className={`btn full-width ${value === calculatePercentage(percentage) ? 'secondary-btn' : 'secondary-outline-btn'}`}>{percentage}%</button>
                            </div>
                        )
                    })}
                </div>
                <div className='margin-bottom-2'>
                    <label htmlFor="tip-amount">Tip amount:</label>
                    <CurrencyInput 
                        blurAction={() => null}
                        setValue={(val) => setValue(val)}
                        name='tip-amount'
                        placeholder='Set tip amount'
                        value={value}
                    />
                </div>
                <div><p className='text-muted'>Your till driver will always retain 100% of tip amount</p></div>
                <div className='dialog-actions'>
                    <div><button onClick={() => {
                        setValue('0.00');
                        setOpen(false);
                    }} className='btn white-btn'>NO TIP</button></div>
                    <div>
                        <button 
                            onClick={() => setOpen(false)} 
                            disabled={disabled} 
                            className={`btn primary-btn${disabled ? ' disabled' : ''}`}
                        >SAVE TIP</button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}