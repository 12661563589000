import React from 'react';
import NumberFormat from 'react-number-format';

const CurrencyInput = ({ value, name, setValue, blurAction, placeholder }) => {
    const [focused, setFocused] = React.useState(false);
    function handleBlur() {
      setFocused(false);
      if (value) {
        const split = value.split('.');
        if (!split[0]) {
          value = '0.' + split[1];
        }
        else if (!split[1]) {
            value = split[0] + '.00';
        }
        else if (split[1].length === 1) {
            value += '0';
        }
        setValue(value);
      }
      else {
        setValue('0.00');
      }
      blurAction(value);
    }
    function handleChange(event) {
      setValue(event.target.value);
    }
    return (
        <div className={`currency-input-container${focused ? ' focused' : ''}`}>
            <div className='dollar-sign'>
                <span>$</span>
            </div>
            <div className='input-container'>
                <NumberFormat
                  decimalScale={2}
                  placeholder={placeholder}
                  name={name}
                  value={value}
                  onChange={handleChange}
                  thousandSeparator
                  type="text"
                  autoComplete="off"
                  autoCorrect="off"
                  onFocus={() => setFocused(true)}
                  onBlur={handleBlur}
                  allowNegative={false}
                  inputMode="decimal"
                />
            </div>
        </div>
    )
}

export default CurrencyInput;