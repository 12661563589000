import React from 'react';
import Dialog from './Dialog';
import Icon from './Icon';

export default ({ inputOrderData, updateOrder }) => {
    const initValue = inputOrderData.deliveryNotes;
    const [open, setOpen] = React.useState(false);
    const [deliveryNotes, setDeliveryNotes] = React.useState(initValue);

    function handleOpen() {
        setOpen(true);
    }
    function handleSave() {
        setOpen(false);
        if (deliveryNotes !== initValue) {
            updateOrder({ deliveryNotes });
        }
    }
    const disabled = deliveryNotes === initValue;
    function renderContent() {
        if (inputOrderData.deliveryNotes) {
            return (
                <div className='checkout-action-container'>
                    <div className='info-container'>
                        <div className='info text-muted' style={{fontSize: '1rem'}}><strong>Delivery notes: </strong>{inputOrderData.deliveryNotes}</div>
                    </div>
                    <div className='action-btn-container'>
                        <button className='btn' title="Edit delivery notes" onClick={handleOpen}><Icon iconName='edit' width={16} /></button>
                    </div>
                </div>
            )
        }
        else {
            return <button className='btn checkout-option-btn' onClick={handleOpen}>Add delivery notes?</button>
        }
    }
    return (
        <>
            <div className='checkout-option-btn-container'>
                {renderContent()}
            </div>
            <Dialog open={open} disableBackdropClick maxWidth={500} >
                <div className='add-padding-2 position-relative'>
                    <button className='btn close-btn' onClick={() => setOpen(false)}>
                        <Icon iconName='times' width={18} />
                    </button>
                    <h5 className='margin-bottom-2'>Add delivery notes</h5>
                    <textarea 
                        className='form-input'
                        placeholder="Let us know how we can help"
                        value={deliveryNotes}
                        onChange={(event) => setDeliveryNotes(event.target.value)}
                    />
                    <button
                        onClick={handleSave}
                        type="submit" 
                        className={`btn primary-btn full-width${disabled ? ' disabled': ''}`} 
                        disabled={disabled}>SAVE</button>
                </div>
            </Dialog>
        </>
    )
}